"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
let imgCache = [];
const imagePattern = new RegExp('^.*\/q(?<q>\\d+)(?:-a(?<a>\\d+))?(?<r>-r)?\\..*$', 'i');
function shuffleArray(array) {
    return [...array].sort(() => Math.random() - .5);
}
function importAll(r) {
    const template = '000000';
    const items = r.keys().map((key) => {
        return r(key).default.match(imagePattern);
    }).filter((value) => {
        return value != null;
    }).sort((a, b) => {
        let aNumber = parseInt(a.groups['q'] + (a.groups['a'] != undefined ? template.replace(new RegExp(`0{${(a.groups['a'] || '0').length}}$`), a.groups['a']) : ''));
        let bNumber = parseInt(b.groups['q'] + (b.groups['a'] != undefined ? template.replace(new RegExp(`0{${(b.groups['a'] || '0').length}}$`), b.groups['a']) : ''));
        return aNumber - bNumber;
    });
    const groups = items.reduce((group, item) => {
        var _a;
        const questionNumber = item.groups['q'];
        group[questionNumber] = (_a = group[questionNumber]) !== null && _a !== void 0 ? _a : [];
        group[questionNumber].push(item);
        return group;
    }, {});
    for (let key in groups) {
        const values = groups[key];
        const rightAnswers = values.filter((item) => !!item.groups['r']);
        imgCache.push({
            questionNumber: values[0].groups['q'],
            questionImage: values[0].input,
            answers: shuffleArray(values.length > 1 ? values.splice(1, values.length).map((item) => item.input) : []),
            rightAnswerImage: rightAnswers.length ? rightAnswers[0].input : null,
        });
    }
}
//@ts-ignore
importAll(require.context('./assets/images', false, /\.(svg|jpe?g|png|webp)$/));
const timeLimitSec = 30 * 60;
const version = 0;
const history = JSON.parse(localStorage.getItem(`data_${version}`) || 'null');
class IqtestCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.currentQuestionIndex = history ? history.currentQuestionIndex : 0;
        this.currentQuestion = history ? history.currentQuestion : undefined;
        this.totalQuestions = imgCache.length;
        this.finished = false;
        this.timeLimitSec = timeLimitSec;
        this.cookieSettings = this.ConfigService.getCookieSettings();
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.questionStep$ = new rxjs_1.Subject();
        this.startWith = history ? history.startWith : timeLimitSec;
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.timerWork = false;
        this.timeRemaining = IqtestCtrl._formatTime(this.startWith);
        this.answers = history ? history.answers : Array(this.totalQuestions);
        $scope.$watch('$ctrl.cookieSettings', (cookieSettings) => {
            this.ConfigService.setCookieSettings(cookieSettings);
        }, true);
        this.$scope.$watch('$ctrl.finished', (finished) => {
            if (finished) {
                this.currentQuestionIndex = 0;
                this.currentQuestion = undefined;
                this.rightAnswerCount = imgCache.filter((item, index) => this.answers[index] == item.rightAnswerImage).length;
                this.rightAnswerIzenk = 112.5 * this.rightAnswerCount / 14;
                this._stopTimer.next();
                this._startTimer.next(null);
            }
        });
        this.$scope.$watch(() => {
            let data = null;
            if (!this.finished && this.currentQuestion) {
                data = {
                    startWith: this.startWith,
                    currentQuestionIndex: this.currentQuestionIndex,
                    currentQuestion: this.currentQuestion,
                    answers: this.answers,
                };
            }
            return data;
        }, (data) => {
            if (data) {
                localStorage.setItem(`data_${version}`, JSON.stringify(data));
            }
            else {
                localStorage.removeItem(`data_${version}`);
            }
        }, true);
    }
    $onInit() {
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            console.log(value);
            if (!value) {
                this.timeRemaining = IqtestCtrl._formatTime(this.startWith);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.concatMap)(() => {
            return this.questionStep$.pipe((0, operators_1.startWith)(0), (0, operators_1.map)((v) => {
                this.currentQuestionIndex = Math.max(0, Math.min(this.currentQuestionIndex + v, imgCache.length));
                return imgCache[this.currentQuestionIndex];
            }));
        }), (0, operators_1.tap)((v) => {
            const skippedQuestionIndex = this.getSkippedQuestionIndex();
            if (skippedQuestionIndex != null) {
                if (v) {
                    this.currentQuestion = v;
                }
                else {
                    this.currentQuestionIndex = skippedQuestionIndex;
                    this.currentQuestion = imgCache[this.currentQuestionIndex];
                }
            }
            else {
                this.finished = true;
            }
        })).subscribe();
        if (history) {
            this._startTimer.next(true);
        }
    }
    start() {
        this.finished = false;
        this.rightAnswerCount = undefined;
        this.rightAnswerIzenk = undefined;
        this.answers = Array(this.totalQuestions);
        this._startTimer.next(true);
        this.questionStep$.next(0);
    }
    setQuestion(index) {
        if ((index >= 0) && (index < imgCache.length)) {
            this.currentQuestionIndex = index;
            this.currentQuestion = imgCache[this.currentQuestionIndex];
        }
    }
    restart() {
        this._stopTimer.next(null);
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./confirm.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                show_timer: this.ConfigService.cookieSettings.show_timer,
                timeRemaining: IqtestCtrl._formatTime(timeLimitSec - this.startWith),
                // cookieSettings: this.ConfigService.cookieSettings
            }
        }).then((result) => {
            if (result == 'newGame') {
                this.currentQuestion = undefined;
                this.currentQuestionIndex = 0;
                this.startWith = timeLimitSec;
                this.timeRemaining = IqtestCtrl._formatTime(this.startWith);
                this._startTimer.next(null);
                localStorage.removeItem(`data_${version}`);
            }
            else {
                this._startTimer.next(null);
                this._startTimer.next(true);
            }
        });
    }
    newGame() {
        this.startWith = timeLimitSec;
        this.start();
    }
    doAnswer(answer, $index) {
        this.answers[$index] = answer;
        this.questionStep$.next(1);
    }
    back() {
        this.questionStep$.next(-1);
    }
    next() {
        this.questionStep$.next(1);
    }
    pauseGame() {
        if (this.ConfigService.cookieSettings.show_timer) {
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "alert-comp",
                scope: this.$scope,
                template: require('./paused.ng.html'),
                strategy: "if_close_all"
            }).then(() => {
                this._resume.next('visible');
            });
        }
    }
    hasAnyAnswer() {
        return this.answers.some((item) => item);
    }
    getSkippedQuestionIndex() {
        let skippedAnswerIndex = null;
        for (var i = 0; i < this.answers.length; i++) {
            if (this.answers[i] == null) {
                skippedAnswerIndex = i;
                break;
            }
        }
        return skippedAnswerIndex;
    }
    currentResult(min, max) {
        if (this.rightAnswerIzenk != undefined) {
            if ((this.rightAnswerIzenk >= min) && (this.rightAnswerIzenk <= max)) {
                return true;
            }
        }
        return false;
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = startWith - i;
            this.$timeout(() => {
                if (sec >= 0) {
                    this.timerWork = true;
                    this.startWith = sec;
                    this.timeRemaining = IqtestCtrl._formatTime(sec);
                }
                else {
                    this.timerWork = false;
                    this.finished = true;
                }
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    static _formatTime(sec) {
        if (sec >= 3600) {
            return new Date(sec * 1000).toISOString().substr(11, 8);
        }
        return new Date(sec * 1000).toISOString().substr(14, 5);
    }
}
IqtestCtrl.$inject = ['$scope', '$timeout', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameIqtest', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: IqtestCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('iqtest/');
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            show_timer: true,
            sound_effects: false,
        });
    }]);
